html body{font-family: $font-family-sans-serif;font-weight: 400;font-size: $font-size-root; line-height: normal;}
main{}
body.open {
    overflow-y: hidden;
    height: 100vh;
}
img {
    max-width: 100%;
    display: inline-block;
}
a {
    transition: all .3s;
    text-decoration: none;
}
ul {
    padding: 0;
    margin: 0;
    li {
        list-style: none;
    }
}
.container-fluid, .container {
    padding-left: 15px;
    padding-right: 15px;
}
p, ul li{
    line-height: 2;
    margin-bottom: 25px;
}
p a{
    color: $primary-color;
}
p b{
    font-weight: 700;
}
ul li{
    margin-bottom: 25px;
    list-style: disc;
}
h1{font-size: $h1-font-size;}
h2{font-size: $h2-font-size;}
h3{font-size: $h3-font-size;}
h4{font-size: $h4-font-size;}
h5{font-size: $h5-font-size;}
a {
    color: inherit;
}
header.sticky{
    -webkit-animation: smoothScroll .5s forwards; -moz-animation: smoothScroll 1s forwards; -ms-animation: smoothScroll 1s forwards;
        -o-animation: smoothScroll 1s forwards;  animation: smoothScroll 1s forwards;
        background: #ffffff;
        padding: 20px 0;
        border-bottom: 2px solid $primary-color;
    a.logo{max-width: 200px;}
    .link{margin-top: 0px;}
    .container{align-items: center;}
}
@-webkit-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-moz-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-o-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-ms-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
.header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1111;
    background: none;
    padding-top: 70px; 
    a.logo{
        max-width: 350px;
        width: 100%;
        display: block;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .link{
        font-size: 1.375rem;
        text-transform: uppercase;
        font-weight: 900;
        color: #4c4c4c;
        margin-top: 30px;
        display: block;
        &:hover{
            color: $primary-color;
        }
    }
}
.btn{
    height: 32px;
    line-height: 30px;
    font-size: 0.9375rem;
    font-weight: normal;
    display: inline-block;
    padding: 0px 25px;
    border-radius: 6px;
    border: 1px solid transparent;
}
.btn-outline{
    color: #ffffff;
    background: transparent;
    border-color: #ffffff;
}
.btn-outline:hover, .btn-outline:focus{
    color: #111111;
    background:#ffffff;
    border-color: #ffffff;
}

.btn-lg{
    height: 58px;
    line-height: 54px;
    font-size: 20px;
}

