
/* START :: Custom variable */
html {
  overflow-x: hidden;
}
img {max-width: 100%;}
html {
font-size: 16px !important;
}
/* START :: Bootstrap variable  */

$body-bg:#ffffff !default;
$body-color: #282728 !default;

$primary-color: #1999d6;
$line-height-base: 1.5;
$green-color:#57b947;
$blue-color:#1999d6;
$purple-color:#462f8c;  
$red-color:#ea1f2f;

$font-size-root: 18px !default;

$font-family-sans-serif :'Raleway', sans-serif;
//$font-secondary :'Ubuntu', sans-serif;

$h1-font-size: 2.25rem;
$h2-font-size: 2.25rem; 
$h3-font-size: 1.75rem;
$h4-font-size: 20px;
$h5-font-size: 16px;

$font-size-34: 2.125rem;
$font-size-28: 1.75rem;
$font-size-25: 1.5625rem;
$font-size-24: 1.5rem;
$font-size-23: 23px;
$font-size-22: 22px;
$font-size-21: 21px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;

$headings-margin-bottom: 25px;
$headings-font-family :  'Raleway', sans-serif;
$headings-font-weight : 900;
$headings-font-style: normal;
$headings-color:#4c4c4c;
$headings-line-height: 1.20;

$container-padding-x: 15px !default;


$paragraph-margin-bottom: 25px !default;

$btn-font-weight : 700;
$btn-font-family: 'Raleway', sans-serif;
$btn-padding-y:  9px;
$btn-padding-x: 30px;
$btn-border-width: 1px;
$btn-focus-width: 0px;


/*END :: Bootstrap variable*/





