.footer{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e4e4e4+0,f7f7f7+29 */
background: rgb(228,228,228); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(228,228,228,1) 0%, rgba(247,247,247,1) 15px); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(228,228,228,1) 0%,rgba(247,247,247,1) 15%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(228,228,228,1) 0%,rgba(247,247,247,1) 15%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4e4e4', endColorstr='#f7f7f7',GradientType=0 ); /* IE6-9 */
    a.logo{
        max-width: 220px;
        width: 100%;
        display: block;
        margin: auto;
        margin-bottom: 40px;
        img{
            width: 100%;
            height: 100%;
        }
    }
   p{
        font-size: 1.375rem;
        font-weight: 900;
        a{
            font-weight: 600;
            font-style: italic;
            color: #282728;
            text-decoration: underline;
            &:hover{
                color: $primary-color;
            }
        }
    }
}