@media (min-width: 1400px) {
   .container{max-width: 1180px;}
   .header .container{max-width: 1550px;}
   .about-sec .row.flex-row-reverse .text-block{padding-right: 50px;padding-left: 0px;}
   .about-sec .row .text-block{padding-left: 50px;}
   .text-block-sec .container{padding-left: 40px;padding-right: 40px;}
   .strategies-sec .green{width: 33%;}
   .strategies-sec .blue{width: 28%;}
   .strategies-sec .purple{width: 21%;}
   .strategies-sec .red{width: 18%;}
}
@media (min-width: 992px) {
    
 }
@media (min-width: 768px) {
    
 } 
@media (max-width: 1366px) {
    
}
@media (max-width: 1440px) {
    .header a.logo{max-width:250px;}
    .banner img{max-width:650px;}
    .header{padding-top: 30px;}
}
@media (max-width: 1199px) {
    html{font-size: 14px !important;}
}
@media (max-width: 991px) {
    html{font-size: 12px !important;}
    html body, p{font-size: 16px;line-height: 1.65;}
    .py-90{padding: 60px 0;}
   .banner{padding-top: 80px;}
   .banner img{max-width:500px;}
   .header a.logo{max-width:200px;}
   .header .link{margin-top: 0px;}
   .header .container{align-items: center;}
}
@media (max-width: 767px) {
    html{font-size: 11px !important;}
    .py-60, .py-90{padding: 40px 0;}
    .footer a.logo{max-width:160px}
    .footer p a{display: block;}
    .strategies-sec .row > *{border-right: none;border-bottom: 1px solid #d6d5d6;padding-top: 25px;}
    .header a.logo, .header.sticky a.logo{max-width:160px;}
    .banner .banner-text-main{padding: 30px 15px;}
    .about-sec .row{padding: 20px 0;}
    .strategies-sec h2{margin-bottom: 25px;}
    .about-sec .text-block{padding-top: 40px;}
 }
 @media (max-width: 575px) {
   
 }
 @media (max-width: 479px) {
  
 }



