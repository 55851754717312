
.py-90{padding: 90px 0;}
.py-60{padding: 60px 0;}
.container{position: relative;z-index: 1;}
main{
    position: relative;
}
.shadow-t{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        background: url(../images/shadow-top.png) no-repeat;
        top: -40px;
        left: 0;
        width: 100%;
        height: 40px;
        background-size: 100%;
    }
}
.shadow-b{
    position: relative;
    &:after{
        content: '';
        position: absolute;
        background: url(../images/shadow-bottom.png) no-repeat;
        bottom: -40px;
        left: 0;
        width: 100%;
        height: 40px;
        background-size: 100%;
    }
}
.banner{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e3d2c8+84,000000+100&0.65+0,0+100 */
background: -moz-linear-gradient(top,  rgba(227,210,200,1) 0%, rgba(227,210,200,1) 90%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(227,210,200,1) 0%,rgba(227,210,200,1) 90%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(227,210,200,1) 0%,rgba(227,210,200,1) 90%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6e3d2c8', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    img{
        mix-blend-mode: darken;
        max-width: 900px;
        width: 100%;
        height: auto;
    }
    .banner-text-main{
        max-width: 980px;
        width: 100%;
        margin: auto;
        background: url(../images/gradient-bg.png) no-repeat;
        padding: 35px;
        background-size: 100% 100%;
        margin-top: -30px;
        .banner-text-box{
            background: #ffffff;
            padding: 30px;
            h1{
                font-size: 2rem;
                line-height: 1.5;
                color: #4c4c4c;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }

}
.about-sec{
    h2{
        text-transform: uppercase;
    }
    .row{
        padding: 50px 0;
    }
    .text-block{
        padding-top: 20px;
    }
}
.img-shadow{
    -webkit-box-shadow: 0px 10px 8px 0px rgba(248,164,49,0.33);
    -moz-box-shadow: 0px 10px 8px 0px rgba(248,164,49,0.33);
    box-shadow: 0px 10px 8px 0px rgba(248,164,49,0.33);
}
.strategies-sec{
    h2{
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    .row > *{
        border-right: 2px solid #d6d5d6;
        &:last-child{border: none;}
    }
    h3{margin-bottom: 15px;}
    .green{
        h3{color: $green-color;}
        .strategies-box{
            max-width: 280px;
            margin: auto;
        }
    }
    .blue{
        h3{color: $blue-color;}
        .strategies-box{
            max-width: 240px;
            margin: auto;
        }
    }
    .purple{
        h3{color: $purple-color;}
        .strategies-box{
            max-width: 170px;
            margin: auto;
        }
    }
    .red{
        h3{color: $red-color;}
    }
}
.text-block-sec{
    h2{
        text-transform: uppercase;
    }
}



